import Service from "./Service";
import {isEmpty} from 'lodash';
const baseurl = '/api/examen-laboratorio';
export default {
    index() {
        return Service.get(`${baseurl}`);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    async list(id,search = '',pagination = {}, id_examen = null) {
        
        let url = `${baseurl}/listar/${id}?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        url += id_examen !== null ? `&id_examen=${id_examen}` : ''; 

        return Service.get(url);
    },

}